<template>
  <layoutContainer>
    <div class="container-box">
      <detailPageCommonHeader currentTitle="新增作品集" backTitle="返回"></detailPageCommonHeader>

      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
        <el-form-item label="作品集名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="作品集类型" prop="businessId">
          <buttonRadioGroup :value.sync="ruleForm.businessId" :businessList="BusinessList.softwareDevelopmentList" />
        </el-form-item>
        <el-form-item label="作品集简介" prop="introduction">
          <el-input type="textarea" v-model="ruleForm.introduction" rows="3"></el-input>
        </el-form-item>
        <el-form-item label="作品集缩略图" prop="lessenPicUrl">
          <uploadTemplate
            @uploadCallBack="uploadCallBack1"
            @deletePic="ruleForm.lessenPicUrl = ''"
            :fileList="[ruleForm.lessenPicUrl]"
          ></uploadTemplate>
        </el-form-item>
        <el-form-item label="作品集图片" prop="picUrl">
          <uploadTemplate
            @uploadCallBack="uploadCallBack"
            @deletePic="i => ruleForm.picUrl.splice(i, 1)"
            :fileList="ruleForm.picUrl"
          ></uploadTemplate>
        </el-form-item>
      </el-form>
      <center>
        <button class="submitButton" @click="validateQueryParams">保存作品集</button>
      </center>
    </div>
  </layoutContainer>
</template>

<script>
import {employeePortfolioCreateApi} from '../../api/personal'
import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState(['UserInfo', 'BusinessList'])
  },
  data() {
    return {
      activeIndex1: 1,
      ruleForm: {
        // companyId: 1, // 公司id
        // name: '', // 案例名
        // businessId: 2, // 承接业务id，1-网站建设，2-app开发，3-小程序开发
        // introduction: '', // 案例介绍
        // casePicUrl: '', // 案例图url列表
        // lessenCasePicUrl: '', // 案例缩略图url
        employeeId: undefined,
        businessId: 1,
        introduction: '',
        name: '',
        lessenPicUrl: '',
        picUrl: []
      },
      rules: {
        name: [
          {required: true, message: '请输入案例名称', trigger: 'blur'},
          {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ], // 案例名
        introduction: [
          {required: true, message: '请输入案例简介', trigger: 'blur'}
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ], // 案例介绍
        picUrl: [{required: true, message: '请上传案例图片', trigger: 'blur'}], // 案例图url列表
        lessenPicUrl: [{required: true, message: '请上传案例缩略图', trigger: 'blur'}] // 案例缩略图url
      }
    }
  },
  created() {
    this.ruleForm.employeeId = this.$route.query.employeeId
    console.log(' this.$route.query.id', this.$route.query.employeeId)
  },
  methods: {
    validateQueryParams() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.submit()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    submit() {
      const params = {...this.ruleForm}
      employeePortfolioCreateApi(params).then(res => {
        this.$message('新增案例成功')
        this.$router.go(-1)
      })
    },
    uploadCallBack(res) {
      console.log(res.data)
      this.ruleForm.picUrl.push(res.data)
    },
    uploadCallBack1(res) {
      console.log(res.data)
      this.ruleForm.lessenPicUrl = res.data
    }
  }
}
</script>

<style scoped lang="scss">
.container-box {
  padding-top: 40px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 150px;
  background: #ffffff;
  /deep/ .el-form-item__label {
    font-size: 18px !important;
    padding: 0 20px 0 0;
  }
}
.check-box {
  display: flex;
  margin-top: 10px;

  div {
    margin-right: 30px;
  }
  li {
    padding: 10px 30px;
  }
  .active {
    background: #55cbc4;
  }
}

.submitButton {
  margin-top: 30px;
  width: 370px;
  height: 46px;
  border: none;
  outline: none;
  background: #5ad3cd;
  border-radius: 4px;
  color: #ffffff;
  font-size: 18px;
}
</style>
