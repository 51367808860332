<template>
  <layoutContainer>
    <div class="introduction">
      <div class="main-message-box">
        <div class="button-box">
          <button v-if="isTeamManager && !isOwner && !isTeamMember" @click="employeeInvite">
            <img src="/img/companyAdd.png" alt="" /> 邀请入团
          </button>
          <button v-if="isOwner || UserInfo.isManage" @click="toPersonalEnter">
            <img src="/img/companyChange.png" alt="" /> 修改信息
          </button>
        </div>
        <div class="left" style=" margin-right: 30px;">
          <avaderImg :size="80" :imgUrl="detail.smallHeadPicUrl" :sex="detail.gender == 1 ? true : false"></avaderImg>
        </div>
        <div class="right">
          <h4>
            {{ detail.nickName }} <img src="/img/dangao@2x.png" alt="" />
            {{ detail.birthDay | yearoldFilters }}
            <img src="/img/xueli@2x.png" alt="" />
            {{ detail.education | EducationListFilter }}
          </h4>
          <p>
            <b>姓名 ：</b>
            <span
              >*** <img src="/img/wenhao.png" alt="" />
              <span class="block-name-remark">为避嫌姓名暂隐，待签约前会显示</span></span
            >
          </p>
          <p>
            <b>职业 ：</b> <span>{{ detail.occupation | EmployeeOccupationListFilter }}</span>
          </p>
          <p>
            <b>地区 ：</b> <span>{{ detail.area | AreaListFilter }}</span>
          </p>
          <p>
            <b>现任公司 ：</b>
            <span v-if="detail.currentCompanyVisible">{{ detail.currentCompany }}</span>
            <span v-else
              >*** <img src="/img/wenhao.png" alt="" />
              <span class="block-name-remark">为避嫌公司名暂隐，待签约前会显示</span></span
            >
          </p>
          <p>
            <b>现任岗位 ：</b> <span>{{ detail.currentStation | EmployeeStationListFilter }}</span>
          </p>
          <p>
            <b>所在团队 ：</b> <span>{{ detail.teamList && detail.teamList[0] }}</span>
          </p>
          <p>
            <b>曾任公司 ：</b>
            <companytag
              v-for="(company, companyIndex) in detail.workedCompany"
              :key="companyIndex"
              :text="company | CompanyListFilter"
            >
            </companytag>
          </p>

          <p>
            <b>擅长领域 ：</b>
            <domaintag
              v-for="(point, pointIndex) in detail.domains"
              :key="pointIndex"
              :text="point | EmployeeDomainListFilter"
            >
            </domaintag>
          </p>
        </div>
      </div>
      <hr v-if="detail.introduction" />
      <div class="intrucetion-detail-text" v-if="detail.introduction">
        <p>个人简介</p>
        <span> {{ detail.introduction }}</span>
      </div>
      <hr v-if="experiencesLis.length" />
      <div class="history-detail-box" v-if="experiencesLis.length">
        <h4>工作经历</h4>
        <el-timeline>
          <el-timeline-item v-for="(activity, index) in experiencesLis" :key="index" color="#37C2BC">
            <h4>
              {{ activity.startYear }}.{{ activity.startMonth }} - {{ activity.endYear }}.{{ activity.endMonth
              }}{{ activity.stationId | AreaListFilter }}
              <span style="margin-left:20px">{{ activity.companyName }}</span>
            </h4>
            <el-card>
              <p class="min-title">{{ activity.occupationId | EmployeeOccupationListFilter }}</p>
              <p class="min-text">{{ activity.duty }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>

    <div class="good-case">
      <h4>
        作品集
        <button class="button-case-add" v-if="isOwner || UserInfo.isManage" @click="addNewProduction">新增作品</button>
      </h4>
      <div class="class-title">网站</div>
      <draggable @end="end" v-model="portfoliosList" class="drag1">
        <transition-group>
          <pcItemWrapper
            :detailPageUrl="`/personal-case-detail/${item.id}`"
            :isOwnCompany="isOwner || UserInfo.isManage"
            v-for="item in portfoliosList"
            v-if="item.businessId != 2 && item.businessId != 3"
            :key="item.id"
            :item="item"
            @deleteCase="employeenPortfolioDelete"
            imgKey="thumbnail"
          />
        </transition-group>
      </draggable>
      <div class="class-title">app</div>
      <draggable @end="end" v-model="portfoliosList" class="drag2">
        <transition-group>
          <appItemWrapper
            :detailPageUrl="`/personal-case-detail/${item.id}`"
            :isOwnCompany="isOwner || UserInfo.isManage"
            v-for="item in portfoliosList"
            v-if="item.businessId == 2 || item.businessId == 3"
            :key="item.id"
            @deleteCase="employeenPortfolioDelete"
            :item="item"
            imgKey="thumbnail"
          >
          </appItemWrapper>
        </transition-group>
      </draggable>
    </div>
  </layoutContainer>
</template>

<script>
import {
  getEmployeeDetailApi,
  employeeInviteApi,
  employeePortfolioSortApi,
  employeenPortfolioDeleteApi
} from '../../api/personal'
import {getTeamDetailApi} from '../../api/team'

import {getTeamInfoApi} from '../../api/team'
import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState(['UserInfo', 'AreaList', 'BusinessList', 'EducationList', 'EmployeeDomainList', 'EmployeeStationList']),
    isOwner() {
      return this.employeeId == this.UserInfo?.employeeId ? true : false
    }
  },

  data() {
    return {
      employeeId: this.$route.params.id || '',
      isTeamManager: false,
      isTeamMember: false,
      teamID: undefined,
      deepList: [],
      detail: {
        area: undefined,
        birthDay: '',
        currentCompany: '',
        currentCompanyVisible: null,
        currentStation: undefined,
        domains: [],
        education: undefined,
        gender: 0,
        headPicUrl: null,
        introduction: '',
        nickName: '',
        occupation: 1,
        realName: '',
        settleTime: '',
        smallHeadPicUrl: null,
        status: 1,
        userId: undefined,
        workYears: 3,
        workedCompany: []
      },
      // JSON.parse(this.$route.query.detail),
      // 工作经历
      experiencesLis: [],
      // 作品集
      portfoliosList: [
        // {
        //   employeeId: 0,
        //   id: 0,
        //   introduction: 'string',
        //   name: 'string',
        //   thumbnail: 'string'
        // }
      ]
    }
  },
  created() {
    this.employeeId = this.$route.params.id
    this.getEmployeeDetail()
    this.sureTeamManager()
  },
  methods: {
    getEmployeeDetail() {
      if (!this.employeeId) return
      getEmployeeDetailApi({employeeId: this.employeeId}).then(res => {
        const resArr = res.data.portfolios.sort((a, b) => a.sequenceNum - b.sequenceNum)
        this.list = res.data
        this.deepList = [...resArr]
        this.experiencesLis = res.data.experiences
        this.portfoliosList = [...resArr]
        this.detail = {...res.data.employeeVo}
      })
    },
    // 邀请入团
    employeeInvite() {
      this.$confirm('确定邀请入团吗？').then(() => {
        employeeInviteApi({
          teamId: this.UserInfo.teamId,
          inviterId: this.UserInfo.employeeId,
          employeeId: Number(this.employeeId)
        }).then(res => {
          if (res.data) {
            this.$message(res.data || '邀请成功')
          }
        })
      })
    },
    addNewProduction() {
      this.$router.push({
        path: '/personal-case-add',
        query: {
          employeeId: this.employeeId
        }
      })
    },
    toPersonalEnter() {
      this.$router.push({
        path: `/personalEnter`,
        query: {
          employeeId: this.employeeId
          // detail: JSON.stringify(this.detail)
        }
      })
    },
    employeenPortfolioDelete(id) {
      this.$confirm('确认删除此案例吗？')
        .then(_ => {
          employeenPortfolioDeleteApi({employeeId: id})
            .then(res => {
              this.getEmployeeDetail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    end(evt) {
      console.log(evt, 'end....')
      this.drag = true
      evt.item //可以知道拖动的本身
      evt.to // 可以知道拖动的目标列表
      evt.from // 可以知道之前的列表
      evt.oldIndex // 可以知道拖动前的位置
      evt.newIndex // 可以知道拖动后的位置
      // employeePortfolioSortApi({caseId: this.detail.id, picUrl: this.portfoliosList.map(item => item.thumbnail)}).then(
      //   res => {
      //     console.log('%c 排序结果....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
      //   }
      // )
      const arr = this.deepList.filter((item, i) => {
        return item.id != this.portfoliosList[i].id
      })
      const sequenceNum1 = arr[0]?.sequenceNum
      const sequenceNum2 = arr[1]?.sequenceNum
      arr[0].sequenceNum = sequenceNum2
      arr[1].sequenceNum = sequenceNum1
      console.log('%c res排序救过....', 'color: red; font-style: italic', JSON.stringify(arr, null, 2))
      console.table(
        this.portfoliosList.map(item => {
          return {id: item.id, sequenceNum: item.sequenceNum}
        })
      )
      console.table(
        this.deepList.map(item => {
          return {id: item.id, sequenceNum: item.sequenceNum}
        })
      )
      employeePortfolioSortApi({
        employeeId: this.employeeId,
        portfolioSeqModelList: this.deepList.map(item => {
          return {portfolioId: item.id, sequenceNum: item.sequenceNum}
        })
      }).then(res => {
        console.log('%c 排序结果....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
      })
    },
    // 确定是否是团队管理员
    sureTeamManager() {
      if (!this.UserInfo.teamId) return
      this.getTeamDetail()
    },
    getTeamDetail() {
      getTeamDetailApi({teamId: this.UserInfo.teamId}).then(res => {
        // console.log('%c getTeamDetailApi....', 'color: red; font-style: italic', JSON.stringify(res.data, null, 2))
        const ismanager = res.data.memberList.filter(item => item.employeeId == this.UserInfo.employeeId)[0]
        this.isTeamMember = res.data.memberList.some(item => item.employeeId == this.employeeId)
        this.isTeamManager =
          ismanager?.isManager ||
          ismanager?.isProductManager ||
          ismanager?.isTeamManager ||
          ismanager?.isTechniqueManager
      })
    }
  },
  watch: {
    // ['detail.currentStation']: {
    //   handler() {
    //     if (this.detail.currentStation) {
    //       // 获取团队信息
    //       getTeamInfoApi({teamId: this.detail.currentStation}).then(res => {
    //         console.log('%c 获取团队信息....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
    //       })
    //     }
    //   },
    //   deep: true
    // }
  }
}
</script>

<style scoped lang="scss">
.drag {
  width: 100%;
  // overflow: hidden;
}
.drag1 > span {
  div {
    display: inline-block;
  }

  > div:nth-child(5n + 2),
  > div:nth-child(5n + 3),
  > div:nth-child(5n + 4),
  > div:nth-child(5n + 5) {
    margin-left: 50px;
  }
}
.drag2 > span {
  div {
    display: inline-block;
  }

  > div:nth-child(4n + 2),
  > div:nth-child(4n + 3),
  > div:nth-child(4n + 4) {
    margin-left: 50px;
  }
}
.introduction {
  background: #ffffff;
  padding: 30px 40px;
  margin: 20px 0;
}
.main-message-box {
  display: flex;
  position: relative;

  .logo-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 30px;
  }
  .right {
    h4 {
      height: 80px;
      line-height: 80px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      img {
        width: 18px;
        margin-right: 6px;
        margin-left: 20px;
      }
    }
    p {
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      font-weight: 400;
      color: #999999;
      font-family: PingFangSC-Regular, PingFang SC;
      b {
        display: inline-block;
        width: 100px;
        text-align: right;
      }
      span {
        color: #333333;
      }
      .block-name-remark {
        display: inline-block;
        width: 240px;
        height: 34px;
        line-height: 34px;
        background: #f6f6f6;
        box-shadow: 0px 2px 4px 0px rgba(220, 220, 220, 0.5);

        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-left: 20px;
      }
    }
    .list-box {
      span {
        display: inline-block;
        height: 24px;
        line-height: 24px;
        font-size: 13px;
        padding: 0 16px;
        border-radius: 12px;
        border: 1px solid #000;
        background: #f6f6f6;
        margin-right: 10px;
      }
    }
  }
}
.button-box {
  position: absolute;
  right: 0;
  top: 0;
  button {
    margin-left: 20px;
    width: 124px;
    height: 40px;
    border-radius: 4px;
    border: 2px solid #5ad3cd;

    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5ad3cd;

    background: #ffffff;
    img {
      height: 20px;
      vertical-align: middle;
    }
  }
}
hr {
  margin-top: 25px;
  margin-bottom: 25px;
}

.intrucetion-detail-text {
  p {
    height: 55px;
    line-height: 55px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  span {
    display: block;
    // text-indent: 33px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
}
.history-detail-box {
  h4 {
    height: 45px;
    line-height: 55px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .min-title {
    height: 40px;
    line-height: 40px;
    font-size: 18px;
  }
  .min-text {
    padding-top: 10px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 30px;
  }
}

.good-case {
  margin-top: 30px;
  background: #ffffff;
  padding: 30px 40px;
  margin: 20px 0;
  h4 {
    position: relative;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    height: 70px;
    line-height: 70px;
  }
  div.class-title {
    // color: #ffca05;

    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    height: 60px;
    line-height: 60px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 200px;
      border: 1px solid #000;
      img {
        display: block;
      }
      .good-case-app-logo {
        height: 300px;
      }
    }
  }
}

/deep/ .el-timeline-item__tail {
  border-left: 1px solid #37c2bc;
}
/deep/ .el-timeline .el-timeline-item:last-child .el-timeline-item__tail {
  display: block;
}

.button-case-add {
  position: absolute;
  right: 0;
  top: 0;
  width: 110px;
  height: 40px;
  background: rgba(56, 194, 187, 0.1);
  border-radius: 4px;
  border: 1px solid #37c2bc;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #37c2bc;
}

hr {
  border: 1px solid #dedede;
  border-top: none;
}
</style>
