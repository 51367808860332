<template>
  <div>
    <layoutContainer>
      <!-- <p>queryParams:{{ queryParams }}</p> -->
      <div class="search-header">
        <div v-if="SearchInput" class="SearchInput">
          {{ SearchInput }}
          <i @click="$store.commit('ChangeSearchInput', '')" :class="'el-icon-error close-icon'"></i>
        </div>
        <checkRadioGroup title="职业" :list="EmployeeOccupationList" :activeIndex.sync="queryParams.occupation"
          keyname="name"></checkRadioGroup>
        <checkRadioGroup title="公司" :list="FamousInternetCompanyShortNameList"
          :activeIndex.sync="queryParams.famousCompanyShortName" keyname="shortName" emitKeyName="shortName">
        </checkRadioGroup>
        <checkRadioGroupLarge title="擅长领域" :list="companyNameList" :hoverList="hoverList"
          :activeIndex.sync="queryParams.domain" :hoverActiveIndex.sync="hoverActive" :allList="EmployeeDomainList"
          keyname="name" @selectHoverLsit="selectHoverLsit" @cancalHoverList="cancalHoverList"
          @changeHoverList="changeHoverList"></checkRadioGroupLarge>
      </div>
      <div class="conpany-list-box">
        <div v-for="(item, i) in companyList" :key="i">
          <persionListItem :item="item" />
        </div>
      </div>
      <center>
        <el-pagination background layout="prev, pager, next" :current-page.sync="queryParams.page"
          :page-size="queryParams.size" :total="queryParams.total" :page-sizes="[10, 20, 30, 40, 50, 100]"
          @current-change="currentChange">
        </el-pagination>
      </center>
    </layoutContainer>
  </div>
</template>

<script>
import { getEmployeeListApi } from '../../api/personal'

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([
      'UserInfo',
      'AreaList',
      'BusinessList',
      'CompanyList',
      'EmployeeDomainList',
      'EducationList',
      'EmployeeOccupationList',
      'EmployeeDomainClassList',
      'EmployeeOccupationDomainClassRelationList',
      'SearchInput',
      'FamousInternetCompanyShortNameList'
    ])
  },
  data() {
    return {
      companyList: [], // 公司列表
      companyNameList: [],
      hoverList: [],
      hoverActive: undefined,
      queryParams: {
        companyNameId: 0,
        occupation: 0, //  0 走所有的业务类型 承接业务id，1-网站建设，2-app开发，3-小程序开发
        domain: 0, //  所在地区id
        famousCompanyShortName: '', // 公司名称
        page: 0, //页码
        size: 8 //页面大小
      },
      isActiveType: false // 是否是子分类
    }
  },
  created() {
    // this.$store.commit('ChangeSearchInput', '')
    this.companyNameList = this.EmployeeDomainClassList
    const { id } = this.$route.query
    if (id) {
      this.queryParams.domain = id
    }
    this.getEmployeeList()
  },
  methods: {
    getEmployeeList() {
      const { occupation, domain, famousCompanyShortName, page, size } = this.queryParams
      let obj = { page, size }

      if (occupation) {
        obj.occupation = occupation
      }
      if (domain) {
        obj.domain = domain
      }
      if (famousCompanyShortName) {
        obj.famousCompanyShortName = famousCompanyShortName
      }
      if (this.SearchInput) {
        obj.keyWord = this.SearchInput
      }
      getEmployeeListApi(obj)
        .then(res => {
          this.companyList = [...res.data.records]
          this.queryParams.page = res.data.current
          this.queryParams.size = res.data.size
          this.queryParams.total = res.data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    selectHoverLsit(id) {
      this.companyNameList = this.EmployeeDomainList
      this.hoverList = []
      this.queryParams.domain = id
    },
    cancalHoverList() {
      this.queryParams.domain = ''
      const relationId = this.EmployeeOccupationDomainClassRelationList.filter(
        item => item.occupationId == this.queryParams.occupation
      )[0]?.domainClassId
      this.companyNameList = relationId
        ? this.EmployeeDomainClassList.filter(item => item.id == relationId)
        : this.EmployeeDomainClassList
      this.hoverList = this.EmployeeDomainList.filter(item => item.classId == relationId)
    },
    changeHoverList(id) {
      this.hoverList = this.EmployeeDomainList.filter(item => item.classId == id)
    },
    currentChange(val) {
      this.queryParams.page = val
      this.getEmployeeList()
    }
  },
  watch: {
    ['$route.query']: {
      immediate: true,
      handler() {
        const query = this.$route.query
        this.queryParams.occupation = query.occupation_id ? query.occupation_id : 0
        this.queryParams.domain = query.proficient_domain_id ? query.proficient_domain_id : 0
        this.queryParams.famousCompanyShortName = query.famous_company_id ? query.famous_company_id : ''
        log.info(this.queryParams.famousCompanyShortName)
      }
    },
    ['queryParams.occupation']: {
      handler() {
        // const relationId = this.EmployeeOccupationDomainClassRelationList.filter(
        //   item => item.occupationId == this.queryParams.occupation
        // )[0]?.domainClassId
        // this.companyNameList = relationId
        //   ? this.EmployeeDomainClassList.filter(item => item.id == relationId)
        //   : this.EmployeeDomainClassList
        // this.hoverList = this.EmployeeDomainList.filter(item => item.classId == relationId)
        // this.queryParams.domain = ''
        this.getEmployeeList()
      },
      immediate: true
    },
    ['queryParams.domain']() {
      this.getEmployeeList()
    },
    ['queryParams.famousCompanyShortName']() {
      this.queryParams.companyName =
        this.CompanyList.filter(item => item.id == this.queryParams.companyNameId)[0]?.shortName || ''
      this.getEmployeeList()
    },
    SearchInput() {
      this.getEmployeeList()
    },
    EmployeeDomainClassList() {
      this.companyNameList = this.EmployeeDomainClassList
    },
    hoverActive(val) { }
  }
}
</script>

<style lang="scss" scoped>
.search-header {
  background: #fff;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 10px 0;
}

// .check-box {
//   display: flex;
//   margin-top: 10px;

//   div {
//     margin-right: 30px;
//   }
//   li {
//     padding: 10px 30px;
//   }
//   .active {
//     background: #55cbc4;
//   }
// }
.conpany-list-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;

  > :nth-child(4n) {
    margin-left: 20px;
  }

  > :nth-child(4n-3) {
    margin-right: 20px;
  }

  > :nth-child(4n-2) {
    margin-right: 20px;
  }

  .company-item {
    border-radius: 8px;
    width: 285px;
    height: 280px;
    overflow: hidden;
    background: #fff;
    margin-bottom: 20px;
    padding: 20px 25px;
    font-family: PingFangSC-Regular, PingFang SC;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    .nick-name {
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 16px;
    }

    .text-button {
      margin-top: 16px;
      margin-bottom: 16px;
      height: 30px;
      line-height: 30px;
      font-size: 16px;

      font-weight: 400;
      color: #ff724c;

      >span {
        width: 60px;
        margin-right: 10px;
        display: inline-block;
        text-align: center;
        background: rgb(255, 241, 238);
      }
    }

    .text-button-round {
      margin-top: 16px;
      margin-bottom: 16px;
      height: 24px;
      line-height: 24px;
      color: #9b9b9b;
      font-weight: 400;
      font-size: 13px;

      >span {
        width: 70px;
        text-align: center;
        background: rgb(246, 246, 246);
        display: inline-block;
        margin-right: 10px;
        border-radius: 12px;
      }
    }

    .address {
      margin-top: 8px;
    }
  }

  //  25 30 25 20
}

.SearchInput {
  display: inline-block;
  margin-left: 160px;
  height: 36px;
  line-height: 36px;
  padding-left: 20px;
  padding-right: 20px;
  background: #5ad3cd;
  color: #fff;
  border-radius: 4px;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;

  >i {
    position: absolute;
    color: #666666;
    font-size: 20px;
    right: -30px;
    top: 8px;
  }
}
</style>
