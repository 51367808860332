<!--
 * @Author: liuzhp
 * @since: 2021-09-16 14:58:13
 * @lastTime: 2022-03-13 23:54:06
 * @LastAuthor: Do not edit
 * @文件相对于项目的路径: /quanqing-fe-web/src/views/personal/personal-case-detail.vue
 * @Description: 
-->
<template>
  <layoutContainer>
    <div class="container-box">
      <detailPageCommonHeader currentTitle="案例" backTitle="返回  "></detailPageCommonHeader>
      <h4>
        {{ detail.name }}
      </h4>

      <p>{{ detail.introduction }}</p>
      <img v-for="(item, i) in detaillist" :key="i" :src="item.picUrl" alt="" @click="opeanPic(item.picUrl)" />
    </div>
  </layoutContainer>
</template>

<script>
import {getEmployeePortfolioDetailApi} from '../../api/personal'

export default {
  data() {
    return {
      caseId: '1',
      detail: {},
      detaillist: []
    }
  },
  created() {
    this.detail = JSON.parse(this.$route.query.detail)
    this.getEmployeePortfolioDetail()
  },
  methods: {
    getEmployeePortfolioDetail() {
      if (!this.detail.id) return
      getEmployeePortfolioDetailApi({portfolioId: this.detail.id}).then(res => {
        this.detaillist = res.data
      })
    },
    opeanPic(url) {
      window.open(url)
    }
  }
}
</script>

<style scoped>
h4 {
  height: 100px;
  line-height: 100px;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  padding-bottom: 30px;
}

img {
  max-width: 100%;
  height: auto;
  margin-bottom: 30px;
  border-radius: 5px;
}

.container-box {
  padding-top: 40px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 150px;
  background: #ffffff;
}
</style>
